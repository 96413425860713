/* eslint-disable no-mixed-operators */
import {UserService, ReportService} from '../services/'

// PLACE_HOLDER reference for ENV is swapped out in the pipeline build using SED. See .github\workflows.
var sedEnv = 'qa'
// console.log('AuthService sedEnv = ', sedEnv)
var NUSSO_URL
var MFA_URL
if (sedEnv === 'dev') {
  NUSSO_URL = 'https://uat-nusso.it.northwestern.edu'
  MFA_URL = 'https%3A%2F%2Fdev-researchportal-ng.entapp.northwestern.edu%2Fduo%2Fmfa.html'
}
if (sedEnv === 'qa') {
  NUSSO_URL = 'https://uat-nusso.it.northwestern.edu'
  MFA_URL = 'https%3A%2F%2Fqa-researchportal-ng.entapp.northwestern.edu%2Fduo%2Fmfa.html'
}
if (sedEnv === 'prod') {
  NUSSO_URL = 'https://prd-nusso.it.northwestern.edu'
  MFA_URL = 'https%3A%2F%2Fresearchportal.northwestern.edu%2Fduo%2Fmfa.html'
}

var COOKIE_NAME = 'webSSOToken'
var LOGIN_URL = NUSSO_URL + '/nusso/XUI/#login?realm=northwestern&authIndexType=service&authIndexValue=ldap-and-duo&goto=' + MFA_URL
var EXTENDED_ATTRIBUTES_URL = process.env.VUE_APP_API_URL + '/user'
// var LOGOUT_URL = NUSSO_URL + '/nusso/XUI/?realm=/northwestern#logout/'

export default {
  showAnnouncements: false,
  loginWindow: null,
  authenticated: false,
  user: null,
  isAuthenticating: false,
  mail: null,
  setRouter: function (router) {
    this.router = router
    this.store = this.router.app.$store
  },
  init: function (opts) {
    opts = opts || {}
    window.addEventListener('message', function (e) { that.receiveMessage(e) }, false)

    var that = this
    this.authenticated = false
    this.isAuthenticating = true

    var sso_session = this.getCookie(COOKIE_NAME)
    if (!sso_session) {
      this.isAuthenticating = false
      console.log('NO session found')
      return
    } else {
      console.log('Got session cookie from document')
    }
    this.authenticateUser()
  },
  getSSOToken () {
    return this.getCookie(COOKIE_NAME)
  },
  login: function () {
    var opts = 'width=360, height=660'
    this.isAuthenticating = true
    this.login_window = window.open(LOGIN_URL, '_blank', opts)
    window.w = this.login_window
    this.login_window.addEventListener('close', function (e) {
      console.log('modal closed', e)
    }, false)
  },
  reset: function () {
    this.store.commit('reset')
    UserService.clear()
    this.authenticated = false
    this.user = null
  },
  logout: function () {
    console.log('Logging out...')
    this.isAuthenticating = false
    this.reset()
    var that = this
    document.cookie = 'webSSOToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    console.log('logged out from SSO')
    var redirect_path = '/home'
    that.router.push({path: redirect_path})
  },
  getCookie: function (name) {
    var cookie = '' + document.cookie.split('; ').filter(function (it) {
      return it.indexOf(name) >= 0
    })
    return cookie.length ? cookie.substring(cookie.indexOf('=') + 1) : null
  },
  hasCookie () {
    return this.getCookie(COOKIE_NAME) && true || false
  },
  authCheck: function () {
    var cookie = this.getCookie(COOKIE_NAME)
    if (cookie) {
      //
      this.authenticateUser()
    } else {
      console.log('No SSO cookie found')
      return false
    }
  },
  getUser: function () {
    return this.user
  },
  receiveMessage: function (event) {

    // if (this.login_window){
    // this.login_window.close();
    // }

    if (event === undefined) {
      console.log('No event. Logging out')
      this.isAuthenticating = false

      return
    } else {
      var VALID_ORIGIN = process.env.VUE_APP_ORIGIN_URL
      if (event.origin !== VALID_ORIGIN) {
      // console.log("invalid origin", event.origin)
        return
      }
    }
    this.authenticateUser()
  },
  authenticateUser: function () {

    var sso_cookie = this.getCookie(COOKIE_NAME)

    if (this.login_window) {
      this.login_window.close()
    }

    if (sso_cookie) {
      console.log('found valid cookie')
      // @TODO: check for cached user attributes.
    } else {
      // console.log('no cookie')
      return
    }

    // Verify that the user went through MFA.  If not log them out?
    // Since we use a local cookie here, we should also perhaps try to make this work
    // even if they have not clicked the login button before?
    var mfa = true
    if (!mfa) {
      console.log('no MFA. Logging out')
      this.isAuthenticating = false
      // redirect to logout
      if (this.login_window) {
        this.login_window.close()
      }

    } else {
      var that = this
      that.getAttributes()

    }
  },
  getAttributes () {
    var url = EXTENDED_ATTRIBUTES_URL + '?sso_token=' + this.getSSOToken()
    var that = this
    fetch(url)
      .then(response => response.json(), function (err) { console.log('fetch error: ' + err) })
      .then(function (attrs) {
        var userdata = {}
        userdata.data = attrs
        that.mail = attrs.mail
        that.user = userdata
        that.isAuthenticating = false
        localStorage.setItem('user', JSON.stringify(that.user))

        console.log('authenticated: ', that.authenticated)
        if (that.authenticated === true) {
          console.info('Already have attrs. skipping')
          return
        }
        userdata = userdata || {}
        if (JSON.stringify(userdata).indexOf('errorMessage') >= 0) {
          console.log('Failed Cognos Response: ', JSON.stringify(userdata.data))
          that.authenticated = false
          that.logout()
          return
        }
        var opts = {
          report_name: 'era-rp-support',
          p_Service: 'RD023',
          p_PrjID: '1'
        }
        ReportService.get(opts, that.store)
          .then(data => {
            if (data.rows.length > 0 && data.rows[0].SalaryAccess === 'Yes') {
              that.showAnnouncements = true
            }
          })

        that.authenticated = true
        var goto_path = localStorage.getItem('goto')

        if (goto_path !== '/home') {
        // localStorage.removeItem('goto')
          return that.router.push({path: goto_path})
        } else {
        // check user role

          var role = that.user.data.RD014_ROLE.role
          switch (role) {
            case 'STAFF':
              goto_path = '/investigators'
              that.router.push({path: goto_path})
              break
            case 'RESEARCHER':
              goto_path = '/projects/' + (that.user.data.uid + '').toUpperCase() + '/sponsored'
              that.router.push({path: goto_path})
              break
            case 'NONE':
              goto_path = '/access_denied'
              that.router.push({path: goto_path})
              // access denied
              break
            default:
              console.log('Unknown role:', that.user.data.eduPersonPrimaryAffiliation)
              goto_path = '/access_denied'
              that.router.push({path: goto_path})
              break
          }
        }
      },
      function (err) {
        that.authenticated = false
        console.log(err)
      })
  }
}
